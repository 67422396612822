import { inject, Injectable } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IServiceApiService } from './service-api.interface';
import { map, Observable, take } from 'rxjs';
import { Service, ServiceCreateOrUpdate } from '../domain/service.model';
import { ServiceApiModel } from './service-api.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceApiService implements IServiceApiService {
  private readonly BASE_URL = environment.baseUrl;
  private _http = inject(HttpClient);

  private _mapResponseToService(response: ServiceApiModel): Service {
    return {
      serviceId: response.serviceId,
      uid: response.uid,
      name: response.name,
      description: response.description,
      price: response.price,
      companyId: response.companyId,
      statusId: response.statusId,
      createdAt: response.createdAt,
      updatedAt: response.updatedAt,
    };
  }

  getServices(): Observable<Service[]> {
    const url = `${this.BASE_URL}services`;
    const headers = new HttpHeaders();
    return this._http.get<ServiceApiModel[]>(url, { headers }).pipe(
      take(1),
      map((response) => response.map(this._mapResponseToService))
    );
  }

  createService(service: ServiceCreateOrUpdate): Observable<Service> {
    const url = `${this.BASE_URL}services`;
    const headers = new HttpHeaders();
    return this._http
      .post<ServiceApiModel>(url, service, { headers })
      .pipe(take(1), map(this._mapResponseToService));
  }

  updateService(
    uuid: string,
    service: ServiceCreateOrUpdate
  ): Observable<Service> {
    const url = `${this.BASE_URL}services/${uuid}`;
    const headers = new HttpHeaders();
    return this._http
      .put<ServiceApiModel>(url, service, { headers })
      .pipe(take(1), map(this._mapResponseToService));
  }

  deleteService(uuid: string): Observable<void> {
    const url = `${this.BASE_URL}services/${uuid}`;
    const headers = new HttpHeaders();
    return this._http.delete<void>(url, { headers });
  }
}
