import { InjectionToken } from '@angular/core';
import { ServiceApiService } from './service-api.service';

export const HTTP_SERVICE_SERVICE = new InjectionToken<ServiceApiService>(
  'ServiceApiService'
);

export const SERVICE_PROVIDER = {
  provide: HTTP_SERVICE_SERVICE,
  useClass: ServiceApiService,
};
